html,
body {
  height: 100%;
  width: 100%;
  line-height: 1.5;
}

body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background-color: #223A5E;
  color: #767676;
}

body.fontLoaded {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

#app {
 display: flex;
 background-color: #223A5E;
 justify-content: center;
 align-items: center;
 width: 100vw;
 height: 100%;
 max-height: 900px;
 position: relative;
}

.container{
    width: 850px;
    height: 850px;
    background: rgb(203,208,209);
    background: linear-gradient(0deg, rgba(203,208,209,1) 29%, rgba(211,213,214,1) 34%, rgba(213,214,215,1) 49%, rgba(249,240,240,1) 100%, rgba(255,255,255,1) 100%);
    padding: 0;
    position: relative;
    .main{
      padding: 0 50px;
    }
}
.home{
  position: relative;
  height: 100%;
}
h3,h2,h1{
  color: #054875;
  font-family: 'Graduate', serif;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}
h1{
  font-size: 30px;
}
h2{
  font-size: 30px;
}
h3{
  font-size: 22px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
