.number{
    font-family: 'MutantAcademyBB', serif;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #8e918e;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    color: white;
    z-index: 1;
    transform: scale(0.5);
    transition-property: all;
    transition-duration: 1s;

    span{
      display: none;
    }

    &.active{
      background: #0076bc;
      transform: scale(1.0);
      box-shadow: 0px 0px 0px 3px #0076bc;

      span{
        box-sizing: border-box;
        display: block;
        border: 2px solid white;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        text-align: center;
      }
    }

    &.blue{
      background: #0076bc;
    }
  }     
  .bar{
    position: absolute;
    top: 20px;
    width: 370px;
    height: 3px;
    background-color: #8e918e;
    left: 15px;
    top: 20px;
  }

  .over{
    position: relative;
    height: 3px;
    background-color: #0076bc;
    left: 10px;
    z-index: 0;
    transition-property: width;
    transition-duration: 1s;
  }